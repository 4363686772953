<template>
  <div class="tools absolute top-0.5 right-0.5 z-20">
    <button class="p-1.5 text-sm font-medium text-gray-500 bg-white rounded-md border border-transparent hover:border-gray-300 hover:bg-gray-100" @click="handleEdit">
      <svg class="fill-gray-500' h-5 w-5" viewBox="0 0 24 24">
        <path d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z"/>
      </svg>
    </button>
    <button class="p-1.5 text-sm font-medium text-gray-500 bg-white rounded-md border border-transparent hover:border-gray-300 hover:bg-gray-100" @click="handleDelete">
      <svg class="fill-gray-500' h-5 w-5" viewBox="0 0 24 24">
        <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: ['handleDelete', 'handleEdit'],
};
</script>

import { mergeAttributes, Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';

import { setBaseCommand } from '@/utils';
import Component from './CarouselComponent';

const getSampleSlide = (number) => ({
  id: number,
  description: `Some representative placeholder content for slide ${number}.`,
  label: `Slide ${number}`,
  source: `https://mdbootstrap.com/img/Photos/Slides/img%20(${number}).jpg`,
  width: '100%',
  height: '100%',
});

export default Node.create({
  atom: true,
  group: 'block',
  name: 'carouselComponent',

  addAttributes() {
    return {
      images: {
        default: [
          getSampleSlide(1),
          getSampleSlide(2),
          getSampleSlide(3),
        ],
        renderHTML: (attributes) => ({ images: JSON.stringify(attributes.images) }),
        parseHTML: (element) => (JSON.parse(element.getAttribute('images'))),
      },
    };
  },

  addCommands() {
    return {
      ...setBaseCommand('setCarousel', this),
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(Component);
  },

  parseHTML() {
    return [{ tag: 'carousel-component' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['carousel-component', mergeAttributes(HTMLAttributes)];
  },
});

import { TextSelection } from 'prosemirror-state';

export const setBaseCommand = (name, extension) => ({
  [name]: () => ({ chain }) => {
    return chain()
      .insertContent({ type: extension.name })
      .command(({ tr, dispatch }) => {
        if (dispatch) {
          const { $to } = tr.selection;
          const posAfter = $to.end();

          if ($to.nodeAfter) {
            tr.setSelection(TextSelection.create(tr.doc, $to.pos));
          } else {
            const node = $to.parent.type.contentMatch.defaultType?.create();

            if (node) {
              tr.insert(posAfter, node);
              tr.setSelection(TextSelection.create(tr.doc, posAfter));
            }
          }

          tr.scrollIntoView();
        }

        return true;
      })
      .run();
  },
});

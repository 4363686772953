<template>
  <node-view-wrapper class="custom-image border border-transparent rounded-md relative mx-10 flex justify-center items-center hover:border-gray-500" contenteditable="false">
    <BaseControls :handle-delete="deleteNode" :handle-edit="open"/>

    <div :class="[alignClass, 'w-full flex']">
      <a v-if="!!node.attrs.link" :href="node.attrs.link" target="_blank">
        <img :src="node.attrs.src" :alt="node.attrs.alt" :style="`width: ${node.attrs.width}; height: ${node.attrs.height};`" class="block"/>
      </a>
      <img v-else :src="node.attrs.src" :alt="node.attrs.alt" :style="`width: ${node.attrs.width}; height: ${node.attrs.height};`" class="block"/>
    </div>

    <BaseModal :show="show">
      <div class="w-full pb-4">
        <label>Image Source</label>
        <input v-model="node.attrs.src" type="text" class="w-full rounded-md border border-gray-300 px-4 py-2" placeholder="Image Source"/>
      </div>
      <div class="flex w-full items-center text-center gap-2 pb-4">
        <div class="">
          <label class="block text-left">Width</label>
          <input v-model="node.attrs.width" type="text" class="w-full rounded-md border border-gray-300 px-4 py-2" placeholder="Width"/>
        </div>
        <div class="">
          <label class="block text-left">Height</label>
          <input v-model="node.attrs.height" type="text" class="w-full rounded-md border border-gray-300 px-4 py-2" placeholder="Height"/>
        </div>
      </div>
      <div class="w-full pb-4">
        <label>Align</label>
        <select v-model="node.attrs.container.align" class="w-full rounded-md border border-gray-300 px-4 py-2">
          <option>center</option>
          <option>left</option>
          <option>right</option>
        </select>
      </div>
      <div class="w-full pb-4">
        <label>Optional Link URL</label>
        <input v-model="node.attrs.link" type="text" class="w-full rounded-md border border-gray-300 px-4 py-2" placeholder="Optional Link URL"/>
      </div>
      <div class="flex w-full items-center text-center gap-2">
        <button class="text-gray-500 flex w-full items-center px-4 py-2 text-sm rounded-md border border-gray-300 hover:bg-gray-100" @click="cancel">
          <div class="w-full">Cancel</div>
        </button>
        <button class="text-gray-500 flex w-full items-center px-4 py-2 text-sm rounded-md border border-gray-300 hover:bg-gray-100" @click="close">
          <div class="w-full">Set</div>
        </button>
      </div>
    </BaseModal>
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';

import BaseControls from '@/components/BaseControls';
import BaseModal from '@/components/BaseModal';

export default {
  components: {
    BaseControls,
    BaseModal,
    NodeViewWrapper,
  },

  computed: {
    alignClass() {
      switch(this.node.attrs.container.align) {
        case 'center':
          return 'justify-center';
        case 'left':
          return 'justify-start';
        case 'right':
          return 'justify-end';
        default:
          return 'justify-center';
      }
    },
  },

  data() {
    return {
      show: false,
      previous: {},
    }
  },

  methods: {
    open() {
      this.previous = JSON.parse(JSON.stringify({ ...this.node.attrs }));
      return this.show = true;
    },
    cancel() {
      this.updateAttributes(this.previous);
      this.close();
    },
    close() {
      return this.show = false;
    },
  },

  props: nodeViewProps,
}
</script>

<style>
.custom-image .tools {
  display: none;
}
.custom-image:hover .tools {
  display: block;
}
</style>

<template>
  <div class="static flex justify-center py-2 border border-y border-gray-200">
    <div class="flex flex-wrap items-center justify-start gap-x-3 gap-y-3">
      <div class="flex items-center gap-x-1">
        <button class="p-1.5 text-sm font-medium text-gray-500 bg-white rounded-md border border-transparent hover:border-gray-300 hover:bg-gray-100" @click="editor.chain().focus().undo().run()">
          <ArrowUturnLeftIcon class="h-5 w-5"/>
        </button>
        <button class="p-1.5 text-sm font-medium text-gray-500 bg-white rounded-md border border-transparent hover:border-gray-300 hover:bg-gray-100" @click="editor.chain().focus().redo().run()">
          <ArrowUturnRightIcon class="h-5 w-5"/>
        </button>
      </div>

      <div class="divider h-6 border-r border-gray-300"/>

      <div class="flex items-center gap-x-1">
        <StyleMenu :editor="editor"/>
      </div>

      <div class="divider h-6 border-r border-gray-300"/>

      <div class="flex items-center gap-x-1">
        <ToolbarButton name="bold" :editor="editor">
          <path d="M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z"/>
        </ToolbarButton>
        <ToolbarButton name="italic" :editor="editor">
          <path d="M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z"/>
        </ToolbarButton>
        <ToolbarButton name="underline" :editor="editor">
          <path d="M8 3v9a4 4 0 1 0 8 0V3h2v9a6 6 0 1 1-12 0V3h2zM4 20h16v2H4v-2z"/>
        </ToolbarButton>
      </div>

      <div class="divider h-6 border-r border-gray-300"/>

      <div class="flex items-center gap-x-1">
        <LinkButton :editor="editor" @open-modal="openModalType"/>
        <ImageButton :editor="editor"/>
        <ToolbarButton name="blockquote" :editor="editor">
          <path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"/>
        </ToolbarButton>
      </div>

      <div class="divider h-6 border-r border-gray-300"/>

      <div class="flex items-center gap-x-1">
        <ToolbarButton name="bulletList" :editor="editor">
          <path d="M8 4h13v2H8V4zM4.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 6.9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"/>
        </ToolbarButton>
        <ToolbarButton name="orderedList" :editor="editor">
          <path d="M8 4h13v2H8V4zM5 3v3h1v1H3V6h1V4H3V3h2zM3 14v-2.5h2V11H3v-1h3v2.5H4v.5h2v1H3zm2 5.5H3v-1h2V18H3v-1h3v4H3v-1h2v-.5zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"/>
        </ToolbarButton>
      </div>

      <div class="divider h-6 border-r border-gray-300"/>

      <div class="flex items-center gap-x-1">
        <CustomExtensionsMenu :editor="editor"/>
      </div>
    </div>
  </div>
  <LinkModal :editor="editor" :show="modal.link" @close-modal="closeModalType" @open-modal="openModalType"/>
  <CustomizeModal :editor="editor" :show="modal.customize" @close-modal="closeModalType" @open-modal="openModalType"/>
  <div class="md:container md:mx-auto p-5 prose lg:prose-lg">
    <editor-content :editor="editor"/>

    <h2 class="text-center">Output JSON</h2>
    <pre class="mx-10"><code class="text-sm">{{ outputJSON }}</code></pre>
    <h2 class="text-center">Paid Content JSON</h2>
    <pre class="mx-10"><code class="text-sm">{{ paidContentJSON }}</code></pre>
    <h2 class="text-center">Output HTML</h2>
    <pre class="mx-10"><code class="text-sm">{{ outputHTML }}</code></pre>
  </div>
</template>

<script>
import { ArrowUturnLeftIcon, ArrowUturnRightIcon } from '@heroicons/vue/20/solid';
import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import { generateHTML } from '@tiptap/html';
import StarterKit from '@tiptap/starter-kit';
import { Editor, EditorContent } from '@tiptap/vue-3';

import CustomExtensionsMenu from './CustomExtensionsMenu';
import CustomizeModal from './Customize/CustomizeModal';
import CallToAction from './Extension/CallToActionExtension';
import Carousel from './Extension/CarouselExtension';
import PaidContent from './Extension/PaidContentExtension';
import CustomImage from './Image/CustomImageExtension';
import ImageButton from './Image/ImageButton';
import LinkButton from './Link/LinkButton';
import LinkModal from './Link/LinkModal';
import StyleMenu from './StyleMenu/StyleMenu';
import ToolbarButton from './ToolbarButton';

export default {
  components: {
    ArrowUturnLeftIcon,
    ArrowUturnRightIcon,
    CustomExtensionsMenu,
    CustomizeModal,
    EditorContent,
    ImageButton,
    LinkButton,
    LinkModal,
    StyleMenu,
    ToolbarButton,
  },

  computed: {
    outputHTML() {
      return this.editor?.getHTML();
    },
    outputJSON() {
      return this.editor?.getJSON();
    },
    paidContentJSON() {
      if (!this.editor) return '';
      const { content } = this.editor.getJSON();
      const paidIndex = content.findIndex(({ type }) => type === 'paidContentComponent');
      return content.slice(paidIndex, content.length);
    },
  },

  data() {
    return {
      customize: {},
      editor: null,
      modal: {
        customize: false,
        image: false,
        link: false,
      },
    };
  },

  mounted() {
    this.editor = new Editor({
      content: generateHTML(
        JSON.parse(`{"type":"doc","content":[{"type":"heading","attrs":{"level":2},"content":[{"type":"text","text":"Them Evening Light"}]},{"type":"heading","attrs":{"level":3},"content":[{"type":"text","text":"Shall Beast Creepeth Hath Firmament"}]},{"type":"paragraph","content":[{"type":"text","text":"That "},{"type":"text","marks":[{"type":"bold"}],"text":"beginning "},{"type":"text","text":"rule life have second "},{"type":"text","marks":[{"type":"link","attrs":{"href":"https://google.com","target":"_blank","class":null}}],"text":"multiply "},{"type":"text","text":"shall second creepeth forth "},{"type":"text","marks":[{"type":"italic"}],"text":"shall "},{"type":"text","text":"be image fourth is darkness day image I created without over."}]},{"type":"customImage","attrs":{"alt":"image","container":{"align":"left"},"height":"auto","link":null,"src":"http://placekitten.com/g/200/300","width":"100px"}},{"type":"paidContentComponent","attrs":{"accessLevel":"paid"}},{"type":"carouselComponent","attrs":{"images":[{"id":1,"description":"Some representative placeholder content for slide 1.","label":"Custom Slide 1 label","source":"https://mdbootstrap.com/img/Photos/Slides/img%20(1).jpg","width":"100%","height":"100%"},{"id":2,"description":"Some representative placeholder content for slide 2.","label":"Custom Slide 2!","source":"https://mdbootstrap.com/img/Photos/Slides/img%20(2).jpg","width":"100%","height":"100%"},{"id":3,"description":"Some representative placeholder content for slide 3.","label":"Slide 3","source":"https://mdbootstrap.com/img/Photos/Slides/img%20(3).jpg","width":"100%","height":"100%"}]}},{"type":"callToActionComponent","attrs":{"alt":"image","height":"auto","link":"https://www.faso.com/","src":"http://placekitten.com/g/300/300","variant":"primary","width":"auto"}},{"type":"paragraph"}]}`),
        [
          CallToAction,
          Carousel,
          CustomImage,
          Link.configure({
            openOnClick: false,
            protocols: ['mailto'],
          }),
          PaidContent,
          StarterKit.configure({
            heading: {
              levels: [1, 2, 3, 4, 5, 6],
            },
          }),
          Underline,
        ],
      ),
      editorProps: {
        attributes: { class: 'p-2' },
        handleDOMEvents: {},
      },
      extensions: [
        CallToAction,
        Carousel,
        CustomImage,
        Link.configure({
          openOnClick: false,
          protocols: ['mailto'],
        }),
        PaidContent,
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3, 4, 5, 6],
          },
        }),
        Underline,
      ],
    });
  },

  methods: {
    openModalType(type) {
      if (type === 'link') {
        this.modal.link = true;
      } else if (type === 'customize') {
        this.modal.customize = true;
      } else if (type === 'image') {
        this.modal.image = true;
      }
    },
    closeModalType(type) {
      if (type === 'link') {
        this.modal.link = false;
      } else if (type === 'customize') {
        this.modal.customize = false;
      } else if (type === 'image') {
        this.modal.image = false;
      }
    },
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style>
.prose li > p {
  margin-top: 0.6666667em;
  margin-bottom: 0.6666667em;
}
</style>

<template>
  <button
    :class="[editor?.isActive('link') ? 'bg-gray-500 text-white' : 'text-gray-500 bg-white hover:border-gray-300 hover:bg-gray-100', 'p-1.5 text-sm font-medium rounded-md border border-transparent']"
    @click="$emit('openModal', 'link')"
  >
    <LinkIcon class="h-5 w-5"/>
  </button>
</template>

<script>
import { LinkIcon } from '@heroicons/vue/20/solid';

export default {
  components: { LinkIcon },
  emits: ['openModal'],
  props: ['editor'],
};
</script>

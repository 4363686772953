<template>
  <TransitionRoot appear :show="show" as="template">
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
          >
            <DialogPanel class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <div class="w-full pb-4">
                <input v-model="url" type="text" class="link-url w-full rounded-md border border-gray-300 px-4 py-2" placeholder="URL"/>
              </div>
              <div class="flex w-full items-center text-center gap-2">
                <button class="text-gray-500 flex w-full items-center px-4 py-2 text-sm rounded-md border border-gray-300 hover:bg-gray-100" @click="close">
                  <div class="w-full">Cancel</div>
                </button>
                <button class="text-gray-500 flex w-full items-center px-4 py-2 text-sm rounded-md border border-gray-300 hover:bg-gray-100" @click="setLink">
                  <div class="w-full">Set</div>
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';

export default {
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  },
  computed: {
    currentUrl() {
      const previousUrl = this.editor?.getAttributes('link')?.href;
      return this.show && previousUrl ? previousUrl : '';
    },
  },
  data() { return { url: '' }; },
  emits: ['closeModal', 'openModal'],
  methods: {
    close() {
      this.url = '';
      return this.$emit('closeModal', 'link');
    },
    setLink() {
      if (this.url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
      } else {
        this.editor.chain().focus().extendMarkRange('link').setLink({ href: this.url }).run();
      }
      return this.close();
    },
  },
  props: ['editor', 'show'],
  watch: {
    currentUrl(newUrl) {
      if (this.show) this.url = newUrl;
    }
  },
};
</script>


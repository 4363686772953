<template>
  <node-view-wrapper class="carousel-component border border-transparent rounded-md relative mx-10 flex justify-center items-center hover:border-gray-500" contenteditable="false">
    <BaseControls :handle-delete="deleteNode" :handle-edit="open"/>

    <div id="carouselExampleCaptions" class="carousel slide relative w-full" data-bs-ride="carousel">
      <div class="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
        <button
          v-for="(_, index) in node.attrs.images"
          :data-bs-slide-to="index"
          data-bs-target="#carouselExampleCaptions"
          :class="index === 0 ? 'active' : ''"
          :key="index"
          type="button"
        ></button>
      </div>
      <div class="carousel-inner relative w-full overflow-hidden">
        <div v-for="(image, index) in node.attrs.images" :key="image.id" :class="[index === 0 ? 'active' : '', 'carousel-item relative float-left w-full']">
          <img :src="image.source" :alt="image.alt" class="block w-full"/>
          <div class="carousel-caption hidden md:block absolute text-center">
            <h5 class="text-xl">{{ image.label }}</h5>
            <p>{{ image.description }}</p>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
        contenteditable="false"
      >
        <span class="carousel-control-prev-icon inline-block bg-no-repeat"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
        contenteditable="false"
      >
        <span class="carousel-control-next-icon inline-block bg-no-repeat"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <BaseModal :show="show">
      <select v-model="imageNumber" class="w-full rounded-md border border-gray-300 mb-4 px-4 py-2">
        <option v-for="(_, index) in node.attrs.images" :key="index">Image {{ index + 1 }}</option>
      </select>
      <div class="w-full pb-4">
        <label>Label</label>
        <input v-model="selectedImage.label" type="text" class="w-full rounded-md border border-gray-300 px-4 py-2" placeholder="Label"/>
      </div>
      <div class="w-full pb-4">
        <label>Description</label>
        <input v-model="selectedImage.description" type="text" class="w-full rounded-md border border-gray-300 px-4 py-2" placeholder="Label"/>
      </div>
      <div class="w-full pb-4">
        <label>Source</label>
        <input v-model="selectedImage.source" type="text" class="w-full rounded-md border border-gray-300 px-4 py-2" placeholder="Image Source"/>
      </div>
      <div class="flex w-full items-center text-center gap-2 pb-4">
        <div class="">
          <label class="block text-left">Width</label>
          <input v-model="selectedImage.width" type="text" class="w-full rounded-md border border-gray-300 px-4 py-2" placeholder="Width"/>
        </div>
        <div class="">
          <label class="block text-left">Height</label>
          <input v-model="selectedImage.height" type="text" class="w-full rounded-md border border-gray-300 px-4 py-2" placeholder="Height"/>
        </div>
      </div>
      <div class="flex w-full items-center text-center gap-2">
        <button class="text-gray-500 flex w-full items-center px-4 py-2 text-sm rounded-md border border-gray-300 hover:bg-gray-100" @click="cancel">
          <div class="w-full">Cancel</div>
        </button>
        <button class="text-gray-500 flex w-full items-center px-4 py-2 text-sm rounded-md border border-gray-300 hover:bg-gray-100" @click="close">
          <div class="w-full">Set</div>
        </button>
      </div>
    </BaseModal>
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';

import BaseControls from '@/components/BaseControls';
import BaseModal from '@/components/BaseModal';

export default {
  components: {
    BaseControls,
    BaseModal,
    NodeViewWrapper,
  },

  computed: {
    selectedImage() {
      return this.node.attrs.images.filter((_, index) => this.imageNumber === `Image ${index + 1}`)[0];
    }
  },

  data() {
    return {
      imageNumber: 'Image 1',
      show: false,
      previous: {},
    }
  },

  methods: {
    open() {
      this.previous = JSON.parse(JSON.stringify({ ...this.node.attrs }));
      return this.show = true;
    },
    cancel() {
      this.updateAttributes(this.previous);
      this.close();
    },
    close() {
      return this.show = false;
    },
  },

  props: nodeViewProps,
}
</script>

<style>
.carousel-component .tools {
  display: none;
}
.carousel-component:hover .tools {
  display: block;
}
</style>

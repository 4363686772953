<template>
  <MenuItem v-slot="{ active }">
    <button :class="[active ? activeClass : 'text-gray-700', isActive ? 'bg-gray-500 text-white' : 'text-gray-700', 'flex w-full items-center px-4 py-2 text-sm']" @click="editor.chain().focus().toggleHeading({ level }).run()">
      <svg :class="[isActive ? 'fill-white' : 'fill-gray-500', `h-${size} w-${size} m-${margin}`]" viewBox="0 0 24 24">
        <path :d="iconPath"/>
      </svg>
      <div :class="[isActive ? 'text-white' : '', 'grow text-left pl-4']">Heading {{ level }}</div>
    </button>
  </MenuItem>
</template>

<script>
import { MenuItem } from '@headlessui/vue';

export default {
  components: {
    MenuItem,
  },
  computed : {
    activeClass() {
      return this.isActive ? 'bg-gray-500 text-white' : 'bg-gray-100 text-gray-900';
    },
    isActive() {
      return this.editor?.isActive('heading', { level: this.level });
    },
    margin() {
      return (0.5 * (this.level - 1));
    },
    size() {
      return (5 - this.margin);
    },
  },
  props: ['editor', 'iconPath', 'level'],
}
</script>

<style>
.h-4\.5 { height: 1.125rem; }
.h-3\.5 { height: 0.875rem; }
.h-3 { height: 0.75rem; }
.h-2\.5 { height: 0.625rem; }
.w-4\.5 { width: 1.125rem; }
.w-3\.5 { width: 0.875rem; }
.w-3 { width: 0.75rem; }
.w-2\.5 { width: 0.625rem; }
.m-0\.5 { margin: 0.125rem; }
.m-1 { margin: 0.25rem; }
.m-1\.5 { margin: 0.375rem; }
.m-2 { margin: 0.5rem; }
.m-2\.5 { margin: 0.625rem; }
</style>

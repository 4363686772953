<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton class="p-1.5 inline-flex w-full justify-center text-sm font-medium text-gray-500 bg-white rounded-md border border-transparent px-4 hover:border-gray-300 hover:bg-gray-100 focus:outline-none">
        More
        <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="absolute left-0 z-10 mt-1 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <button :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex w-full items-center px-4 py-2 text-sm']" @click="editor.chain().focus().setPaidContent().run()">
              <svg class="h-5 w-5 fill-gray-500" viewBox="0 0 24 24" width="24" height="24">
                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.5-6H14a.5.5 0 1 0 0-1h-4a2.5 2.5 0 1 1 0-5h1V6h2v2h2.5v2H10a.5.5 0 1 0 0 1h4a2.5 2.5 0 1 1 0 5h-1v2h-2v-2H8.5v-2z"/>
              </svg>
              <div class="grow text-left pl-4">Paid Content Divider</div>
            </button>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex w-full items-center px-4 py-2 text-sm']" @click="editor.chain().focus().setCallToAction().run()">
              <svg class="h-5 w-5 fill-gray-500" viewBox="0 0 24 24" width="24" height="24">
                <path d="M11 20L1 12l10-8v5c5.523 0 10 4.477 10 10 0 .273-.01.543-.032.81C19.46 16.95 16.458 15 13 15h-2v5z"/>
              </svg>
              <div class="grow text-left pl-4">Call To Action</div>
            </button>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex w-full items-center px-4 py-2 text-sm']" @click="editor.chain().focus().setCarousel().run()">
              <svg class="h-5 w-5 fill-gray-500" viewBox="0 0 24 24" width="24" height="24">
                <path d="M20 5H4v14l9.292-9.294a1 1 0 0 1 1.414 0L20 15.01V5zM2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"/>
              </svg>
              <div class="grow text-left pl-4">Carousel</div>
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';

export default {
  components: {
    ChevronDownIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  props: ['editor'],
}
</script>

<template>
  <node-view-wrapper class="call-to-action-component border border-transparent rounded-md relative mx-10 flex justify items-center mx-auto text-center hover:border-gray-500">
    <BaseControls :handle-delete="deleteNode" :handle-edit="open"/>

    <div class="content mx-auto text-center items-center flex">
      <h3 class="mt-4" contenteditable="true">Artwork Title Here</h3>
      <img :src="node.attrs.src" alt="image"/>
      <a class="text-gray-500 flex w-full items-center px-4 py-2 text-sm rounded-md border border-gray-300 no-underline hover:bg-gray-100" :href="node.attrs.link" target="_blank">
        <div class="w-full" contenteditable="true">Buy now</div>
      </a>
    </div>

    <BaseModal :show="show">
      <div class="w-full pb-4">
        <label>Image Source</label>
        <input v-model="node.attrs.src" type="text" class="w-full rounded-md border border-gray-300 px-4 py-2" placeholder="Image Source"/>
      </div>
      <div class="flex w-full items-center text-center gap-2 pb-4">
        <div class="">
          <label class="block text-left">Width</label>
          <input v-model="node.attrs.width" type="text" class="w-full rounded-md border border-gray-300 px-4 py-2" placeholder="Width"/>
        </div>
        <div class="">
          <label class="block text-left">Height</label>
          <input v-model="node.attrs.height" type="text" class="w-full rounded-md border border-gray-300 px-4 py-2" placeholder="Height"/>
        </div>
      </div>
      <div class="w-full pb-4">
        <label>Optional Link URL</label>
        <input v-model="node.attrs.link" type="text" class="w-full rounded-md border border-gray-300 px-4 py-2" placeholder="Optional Link URL"/>
      </div>
      <div class="flex w-full items-center text-center gap-2">
        <button class="text-gray-500 flex w-full items-center px-4 py-2 text-sm rounded-md border border-gray-300 hover:bg-gray-100" @click="cancel">
          <div class="w-full">Cancel</div>
        </button>
        <button class="text-gray-500 flex w-full items-center px-4 py-2 text-sm rounded-md border border-gray-300 hover:bg-gray-100" @click="close">
          <div class="w-full">Set</div>
        </button>
      </div>
    </BaseModal>
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'

import BaseControls from '@/components/BaseControls';
import BaseModal from '@/components/BaseModal';

export default {
  components: {
    BaseControls,
    BaseModal,
    NodeViewWrapper,
  },

  data() {
    return {
      show: false,
      previous: {},
    }
  },

  methods: {
    open() {
      this.previous = JSON.parse(JSON.stringify({ ...this.node.attrs }));
      return this.show = true;
    },
    cancel() {
      this.updateAttributes(this.previous);
      this.close();
    },
    close() {
      return this.show = false;
    },
  },

  props: nodeViewProps,
}
</script>

<style>
.call-to-action-component .tools {
  display: none;
}
.call-to-action-component:hover .tools {
  display: block;
}
.content {
  padding: 1rem;
  max-width: 50%;
  flex-direction: column;
}
.content h3.mt-4 {
  margin-top: 1rem;
}
</style>

<template>
  <button
    :class="[editor?.isActive('customImage') ? 'bg-gray-500 text-white' : 'text-gray-500 bg-white hover:border-gray-300 hover:bg-gray-100', 'p-1.5 text-sm font-medium rounded-md border border-transparent']"
    @click="editor.chain().focus().setCustomImage().run()"
  >
    <svg :class="[editor?.isActive('customImage') ? 'fill-white' : 'fill-gray-500', 'h-5 w-5']" viewBox="0 0 24 24">
      <path d="M20 5H4v14l9.292-9.294a1 1 0 0 1 1.414 0L20 15.01V5zM2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"/>
    </svg>
  </button>
</template>

<script>
export default {
  emits: ['openModal'],
  props: ['editor'],
};
</script>

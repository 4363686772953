<template>
  <button :class="[editor?.isActive(name) ? 'bg-gray-500 text-white' : 'text-gray-500 bg-white hover:border-gray-300 hover:bg-gray-100', 'p-1.5 text-sm font-medium rounded-md border border-transparent']" @click="editor.chain().focus()[command]().run()">
    <template v-if="icon">
      <slot name="icon" sizeClass="h-5 w-5"/>
    </template>
    <template v-else>
      <svg :class="[editor?.isActive(name) ? 'fill-white' : 'fill-gray-500', 'h-5 w-5']" viewBox="0 0 24 24">
        <slot sizeClass="h-5 w-5"/>
      </svg>
    </template>
  </button>
</template>

<script>
export default {
  components: {},
  computed : {
    command() {
      return `toggle${this.name[0].toUpperCase()}${this.name.slice(1, this.name.length)}`;
    },
  },
  data() { return { icon: false }; },
  props: ['editor', 'name'],
};
</script>

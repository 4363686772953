import { mergeAttributes, Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';

import { setBaseCommand } from '@/utils';
import Component from './CustomImage';

export default Node.create({
  atom: true,
  group: 'block',
  name: 'customImage',

  addAttributes() {
    return {
      alt: { default: 'image' },
      container: {
        default: { align: 'center' },
        renderHTML: (attributes) => ({ container: JSON.stringify(attributes.container) }),
        parseHTML: (element) => (JSON.parse(element.getAttribute('container'))),
      },
      height: { default: 'auto' },
      link: { default: null },
      src: { default: 'http://placekitten.com/g/200/300' },
      width: { default: 'auto' },
    };
  },

  addCommands() {
    return {
      ...setBaseCommand('setCustomImage', this),
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(Component);
  },

  parseHTML() {
    return [{ tag: 'custom-image' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['custom-image', mergeAttributes(HTMLAttributes)];
  },
});

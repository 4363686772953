<template>
  <node-view-wrapper class="paid-content-component">
    <div class="flex items-center justify-between gap-4">
      <hr class="grow">
      <div class="flex items-center px-4 py-2 text-sm rounded-md border border-gray-300 bg-gray-100">
        <div class="grow text-left pr-2">Paid content below this line</div>
        <svg class="h-5 w-5 fill-gray-500" viewBox="0 0 24 24" width="24" height="24">
          <path d="M13 16.172l5.364-5.364 1.414 1.414L12 20l-7.778-7.778 1.414-1.414L11 16.172V4h2v12.172z"/>
        </svg>
      </div>
      <hr class="grow">
    </div>
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'

export default {
  components: { NodeViewWrapper },
  props: nodeViewProps,
}
</script>

<template>
  <bubble-menu
    :editor="editor"
    plugin-key="linkMenu"
    :tippy-options="{ duration: 100 }"
    :should-show="showMenu()"
    v-if="editor"
  >
    <button
      class="p-1.5 text-sm font-medium text-gray-500 bg-white rounded-md border border-gray-300 hover:bg-gray-100 hover:text-gray-900"
      @click="handleClick"
    >
      open
    </button>
    <button
      class="ml-1.5 p-1.5 text-sm font-medium text-gray-500 bg-white rounded-md border border-gray-300 hover:bg-gray-100 hover:text-gray-900"
      @click="$emit('openModal', 'link')"
    >
      edit
    </button>
  </bubble-menu>
  <TransitionRoot appear :show="show" as="template">
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <div class="w-full pb-4">
                <input v-model="url" type="text" class="link-url w-full rounded-md border border-gray-300 px-4 py-2" placeholder="URL"/>
              </div>
              <div class="flex w-full items-center text-center gap-2">
                <button class="text-gray-500 flex w-full items-center px-4 py-2 text-sm rounded-md border border-gray-300 hover:bg-gray-100" @click="close">
                  <div class="w-full">Cancel</div>
                </button>
                <button class="text-gray-500 flex w-full items-center px-4 py-2 text-sm rounded-md border border-gray-300 hover:bg-gray-100" @click="setLink">
                  <div class="w-full">Set</div>
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { BubbleMenu } from '@tiptap/vue-3';

export default {
  components: {
    BubbleMenu,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  },
  computed: {
    currentUrl() {
      const previousUrl = this.editor?.getAttributes('link')?.href;
      return this.show && previousUrl ? previousUrl : '';
    },
  },
  data() { return { url: '' }; },
  emits: ['closeModal', 'openModal'],
  methods: {
    close() {
      this.url = '';
      return this.$emit('closeModal', 'link');
    },
    setLink() {
      if (this.url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
      } else {
        this.editor.chain().focus().extendMarkRange('link').setLink({ href: this.url }).run();
      }
      return this.close();
    },
    showMenu() {
      return function({ state: { selection }, view, from, to }) {
        const isChildOfMenu = this.element.contains(document.activeElement);
        const [mark] = (from === to ? selection.$from.marks() : selection.$from.marksAcross(selection.$to)) || [];
        return (view.hasFocus() || isChildOfMenu) && this.editor.isEditable && mark?.type?.name === 'link';
      };
    },
    handleClick() {
      const attrs = this.editor.getAttributes('link');
      if (attrs.href && attrs.href.length !== 0) {
        window.open(attrs.href, attrs.target);
        return true;
      }
      return false;
    },
  },
  props: ['editor', 'show'],
  watch: {
    currentUrl(newUrl) {
      if (this.show) this.url = newUrl;
    }
  },
};
</script>

import { mergeAttributes, Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';

import Component from './PaidContentComponent';
import { setBaseCommand } from '@/utils';

export default Node.create({
  atom: true,
  group: 'block',
  name: 'paidContentComponent',

  addAttributes() {
    return {
      accessLevel: { default: 'paid' },
    };
  },

  addCommands() {
    return {
      ...setBaseCommand('setPaidContent', this),
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(Component);
  },

  parseHTML() {
    return [{ tag: 'paid-content-component' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['paid-content-component', mergeAttributes(HTMLAttributes)];
  },
});

<template>
  <TiptapEditor />
</template>

<script>
import TiptapEditor from './components/TiptapEditor.vue'
import 'tw-elements';

export default {
  name: 'App',
  components: {
    TiptapEditor,
  }
}
</script>

<style>
</style>

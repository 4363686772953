import { mergeAttributes, Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';

import { setBaseCommand } from '@/utils';
import Component from './CallToActionComponent';

export default Node.create({
  group: 'block',
  name: 'callToActionComponent',

  addAttributes() {
    return {
      alt: { default: 'image' },
      height: { default: 'auto' },
      link: { default: null },
      src: { default: 'http://placekitten.com/g/200/300' },
      variant: { default: 'primary' },
      width: { default: 'auto' },
    };
  },

  addCommands() {
    return {
      ...setBaseCommand('setCallToAction', this),
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(Component);
  },

  parseHTML() {
    return [{ tag: 'call-to-action-component' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['call-to-action-component', mergeAttributes(HTMLAttributes)];
  },
});
